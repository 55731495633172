import React from "react"
import styled from "styled-components"

import AppLayout from "../../../Components/AppLayout"
import { Box } from "../../../Components/Base"

import WorkList from "./WorkList"

const WorkPageGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: fit-content fit-content fit-content;
  width: 100%;
`

const Header = styled(Box)`
  grid-column-start: 1;
  grid-column-end: ${props => (props.fullWidth ? 4 : 3)};
  grid-row-start: 1;
  grid-row-end: 2;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
  }
`

const SideBar = styled(Box)`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    display: none;
  }
`

const Content = styled(Box)`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 2;
  }
`

const Footer = styled(Box)`
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 3;
  grid-row-end: 4;
`

const WorkPageLayout = ({
  header,
  sidebar,
  fullWidthHeader,
  content,
  currentWork,
  ...restProps
}) => {
  return (
    <AppLayout>
      <WorkPageGrid {...restProps}>
        <Header fullWidth={fullWidthHeader} pb={[3, 5]}>
          {header}
        </Header>
        {/* <SideBar>
          <WorkList />
        </SideBar> */}
        <Content>{content}</Content>
        {/* <Footer>
          <WorkList currentWork={currentWork}/>
        </Footer> */}
      </WorkPageGrid>
    </AppLayout>
  )
}

export default WorkPageLayout
