import React from "react"
import styled from "styled-components"

import { Box, Flex, Text, Image } from "../../../Components/Base"

export const ContentHeader = props => (
  <Text
    as={"h1"}
    fontSize={[8, 9]}
    fontWeight={"medium"}
    // letterSpacing={"4px"}
    mt={0}
    mb={0}
    {...props}
  />
)
export const ContentSubHeader = props => (
  <Text
    as={"h2"}
    fontSize={[4, 5]}
    fontWeight={"medium"}
    color={"greyscale.grey7"}
    mt={0}
    mb={4}
    {...props}
  />
)
export const ContentHeaderParagraph = props => (
  <Text fontSize={[3, 4]} mb={4} {...props} />
)
export const ContentTitle = props => (
  <Text
    as={"h2"}
    fontSize={[6, 7]}
    fontWeight={"medium"}
    mt={3}
    mb={3}
    {...props}
  />
)
export const ContentParagraph = props => (
  <Text fontSize={3} fontWeight={"light"} pt={2} mb={4} {...props} />
)

export const ContentCaption = props => (
  <Text fontSize={2} fontWeight={"light"} {...props} />
)

export const ContentImage = ({
  caption,
  src,
  height,
  style,
  maxHeight,
  ...restProps
}) => (
  <Box display={"inline-block"} style={{ verticalAlign: "top" }} {...restProps}>
    <Image
      src={src}
      style={{ objectFit: "cover", ...style }}
      width={"100%"}
      height={height}
      maxHeight={maxHeight}
    />
    {caption && (
      <Text
        fontSize={1}
        mt={2}
        mb={4}
        px={2}
        color={"greyscale.grey5"}
        textAlign={"center"}
      >
        {caption}
      </Text>
    )}
  </Box>
)

export const ContentTextField = ({ title, textAlign, fields, size }) => {
  const fontSize = size === "small" ? 1 : 2
  return (
    <Box>
      {title && (
        <Text
          fontSize={fontSize}
          textAlign={textAlign}
          fontWeight={"medium"}
          mb={2}
        >
          {title}
        </Text>
      )}

      {fields.map(({ field, value }) => {
        return (
          <Box mb={size === "small" ? 2 : 3}>
            {field && (
              <Text
                fontSize={fontSize}
                textAlign={textAlign}
                fontWeight={"medium"}
                mb={1}
              >
                {field}:
              </Text>
            )}
            {Array.isArray(value) ? (
              <Box>
                {value.map(v => (
                  <Text
                    fontSize={fontSize}
                    textAlign={textAlign}
                    fontWeight={"light"}
                  >
                    {v}
                  </Text>
                ))}
              </Box>
            ) : (
              <Text
                fontSize={fontSize}
                textAlign={textAlign}
                fontWeight={"light"}
              >
                {value}
              </Text>
            )}
          </Box>
        )
      })}
    </Box>
  )
}

const ContentList = styled.ul`
  margin: 0;
`
const ContentListItem = styled(props => <Box as={"li"} mb={3} {...props} />)``

const ContentSection = ({
  title,
  fullWidth = true,
  left,
  right,
  leftProps,
  rightProps,
  children,
  ...restProps
}) => {
  return (
    <>
      {title && (
        <ContentTitle mb={"48px"} textAlign={"center"}>
          {title}
        </ContentTitle>
      )}
      <Flex flexGrow={1} flexWrap={"wrap"} mb={6} {...restProps}>
        {!fullWidth && (
          <Box width={["100%", "100%", "50%"]} {...leftProps}>
            {left}
          </Box>
        )}
        {!fullWidth && (
          <Box width={["100%", "100%", "50%"]} {...rightProps}>
            {right}
          </Box>
        )}
        {fullWidth && children}
      </Flex>
    </>
  )
}
const ContentDetailCard = ({
  imageSrc,
  useSrcSet,
  description,
  index,
  imageProps,
  descriptionProps,
  descriptionWithIndexContainerProps,
  ...restProps
}) => {
  return (
    <Box p={3} pt={2} pb={4} {...restProps}>
      {imageSrc && (
        <Image src={imageSrc} useSrcSet={useSrcSet} mb={2} {...imageProps} />
      )}
      {!index && description && (
        <ContentCaption {...descriptionProps}>{description}</ContentCaption>
      )}
      {index && description && (
        <Flex alignItems={"baseline"} {...descriptionWithIndexContainerProps}>
          <Text mr={2} fontSize={5} flexShrink={0}>
            {index}.{" "}
          </Text>
          <ContentCaption>{description}</ContentCaption>
        </Flex>
      )}
    </Box>
  )
}

const Content = props => <Box {...props} />
Content.Header = ContentHeader
Content.SubHeader = ContentSubHeader
Content.HeaderParagraph = ContentHeaderParagraph
Content.Title = ContentTitle
Content.Paragraph = ContentParagraph
Content.Image = ContentImage
Content.TextField = ContentTextField
Content.List = ContentList
Content.ListItem = ContentListItem
Content.DetailCard = ContentDetailCard
Content.Caption = ContentCaption
Content.Section = ContentSection

export default Content
