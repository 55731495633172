import React from "react"

import WorkPageLayout from "./Components/WorkPageLayout"
import { Box, Flex } from "../../Components/Base"
import { WORK_ID, WORK } from "../../constant"

import Content from "./Components/Content"

const workId = WORK_ID.TRAT
const workData = WORK.find(w => w.key === workId)
const ROOT_IMAGE_PATH = `/assets/work/${workId}`

const HeaderSection = () => {
  return (
    <Box>
      <Content.Header>{workData.name}</Content.Header>
      <Content.SubHeader>role: {workData.role}</Content.SubHeader>
      <Flex flexDirection={["column", "row"]} justifyContent={"space-between"}>
        <Content.HeaderParagraph width={"70%"}>
          Located on the top of the mountain with the treetop view of a green
          woods and the gulf of Thailand, the project is definitely located on
          one of the best site in Trat province. The team was requested to
          design a private residence for a family of three to use as a leisure
          space during holidays and stay away from the chaos of the city. The
          requirements were simple, therefore the team decided to focus more on
          the connectivity between the context and the house to create the safe
          and relaxing space for the family.
        </Content.HeaderParagraph>
        <Content.TextField
          textAlign={"right"}
          size={"small"}
          fields={[
            {
              field: "location",
              value: "Trat, Thailand",
            },
            { field: "program", value: "private house" },
            { field: "status", value: "completion 2017" },
            { field: "area", value: "626 sq.m." },
          ]}
        />
      </Flex>
    </Box>
  )
}

const ContentSection = () => {
  return (
    <Content as={"article"}>
      <Content.Section>
        <Content.Image
          src={`/assets/work/${workId}/thumbnail.jpg`}
          height={"500px"}
          width={"100%"}
          style={{
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </Content.Section>

      <Content.Section title={"Idea"}>
        <Content.Paragraph>
          Because of the stunning location, the challenge of this project is to
          find the solution on how to immerse the human-made building into the
          surrounded nature and take the advantage of the best view around the
          area. After several iterations of schematic design, we decided to
          simply lift the main functions up to take the view and at the same
          time create the seamless connectivity both vertically and
          horizontally, from ground to treetop, from inside to outside.
        </Content.Paragraph>
        <Content.DetailCard
          useSrcSet={true}
          width={["100%", "50%", null]}
          imageSrc={`${ROOT_IMAGE_PATH}/diagram/d-01/d-01@3x.png`}
          index={1}
          description={"Separate functions into public and private"}
        />
        <Content.DetailCard
          useSrcSet={true}
          width={["100%", "50%", null]}
          imageSrc={`${ROOT_IMAGE_PATH}/diagram/d-02/d-02@3x.png`}
          index={2}
          description={
            "Lift the private functions up for privacy and the treetop view "
          }
        />
        <Content.DetailCard
          useSrcSet={true}
          width={["100%", "50%", null]}
          imageSrc={`${ROOT_IMAGE_PATH}/diagram/d-03/d-03@3x.png`}
          index={3}
          description={
            "Spiral the public functions and seamlessly connect spaces from the ground to treetop"
          }
        />
        <Content.DetailCard
          useSrcSet={true}
          width={["100%", "50%", null]}
          imageSrc={`${ROOT_IMAGE_PATH}/diagram/d-04/d-04@3x.png`}
          index={4}
          description={
            "Reflective and translucent facade are used to connect inside-outside spaces, while double concrete slabs create shade and at the same time emphasize the ground-treetop connected line"
          }
        />
      </Content.Section>

      <Content.Section
        mb={5}
        fullWidth={false}
        left={
          <>
            <Content.Title>Ground-Treetop</Content.Title>
            <Content.Paragraph pr={5}>
              Fot the guests, the spiral circulation directly but gradually
              leads them to the treetop view and at the same time offers the
              changing scenes of the surrounded nature along the path. On the
              other hand, the beauty of the woods are offered to the owners as
              transitions of activity while moving around the functions through
              this slope.
            </Content.Paragraph>
            <Content.Paragraph pr={5}>
              Two white concrete slabs are designed to create shades for the
              certain spaces and at the same time emphasize the lines of the
              smooth ground-treetop continuation.
            </Content.Paragraph>
          </>
        }
        right={
          <Content.Image
            src={`/assets/work/${workId}/exterior-02.jpg`}
            height={"450px"}
            style={{
              objectFit: "cover",
              objectPosition: "bottom",
            }}
          />
        }
      />
      <Content.Section>
        <Content.Image
          src={`/assets/work/${workId}/roof-01.jpg`}
          height={"450px"}
          caption={
            "spiral circulation create the seamless connectivity from the ground to the roof deck"
          }
          style={{
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      </Content.Section>

      <Content.Section
        mb={5}
        fullWidth={false}
        left={
          <>
            <Content.Title>In-Out</Content.Title>
            <Content.Paragraph pr={5}>
              Materials like glass and reflective aluminum panel are employed as
              facade to offer continuation of interior-exterior and at the same
              time reflects surroundings, which creates blurring effect and
              blend the architecture with the surroundings. The full-height
              glass window is used in the most area to fully connect interior
              and exterior while the reflective aluminum panels are used to
              offer privacy for certain areas.
            </Content.Paragraph>
          </>
        }
        right={
          <Content.Image
            src={`/assets/work/${workId}/detail-01.jpg`}
            height={"450px"}
            style={{
              objectFit: "cover",
              objectPosition: "bottom",
            }}
          />
        }
      />

      <Content.Section>
        <Content.Image
          src={`/assets/work/${workId}/interior-01.jpg`}
          width={"100%"}
          height={"440px"}
          caption={
            "natural light pass through the full-height windows to lighten the space and create the effect of shade and shadow"
          }
        />
      </Content.Section>

      <Content.Section title={"Gallery"}>
        <Content.Image
          src={`/assets/work/${workId}/plan-01.png`}
          width={["100%", "50%"]}
          height={["auto", "450px"]}
          caption={"1st floor plan"}
        />
        <Content.Image
          src={`/assets/work/${workId}/plan-02.png`}
          width={["100%", "50%"]}
          height={["auto", null, "450px"]}
          caption={"2nd floor plan"}
        />
        <Content.Image
          src={`/assets/work/${workId}/interior-02.jpg`}
          width={["100%", "50%"]}
          caption={
            "a shortcut stair directly connect the entrance foyer with the private area of the house"
          }
          height={"430px"}
          style={{
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
        <Content.Image
          src={`/assets/work/${workId}/interior-04.jpg`}
          width={["100%", "50%"]}
          caption={"full-height windows blur the interior-exterior line "}
          height={"430px"}
          style={{
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
        <Content.Image
          src={`/assets/work/${workId}/entrance-01.jpg`}
          width={"100%"}
          height={"300px"}
          caption={"view from entrance"}
          style={{
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
        <Content.Image
          src={`/assets/work/${workId}/roof-06.jpg`}
          width={"100%"}
          height={"750px"}
          caption={
            "the white concrete slabs emphasize the connected line from the ground to the roof deck"
          }
          style={{
            objectFit: "cover",
            objectPosition: "bottom",
          }}
        />
        <Content.Image
          src={`/assets/work/${workId}/roof-03.jpg`}
          width={"100%"}
          caption={"the treetop view on the roof deck"}
        />
      </Content.Section>

      <Content.Section>
        <Content.TextField
          fields={[
            {
              field: "Architect",
              value: [
                "Kanop Mangklapruk",
                "Kanin Umgumnert",
                "Tadpol Rachatasumrit",
                "Chachawan Subsuensakul",
              ],
            },
            { field: "Photographer", value: "Supakorn srisakul" },
          ]}
        />
      </Content.Section>
    </Content>
  )
}
const Work = () => {
  return (
    <WorkPageLayout
      header={<HeaderSection />}
      fullWidthHeader={true}
      content={<ContentSection />}
      currentWork={workId}
    />
  )
}

export default Work
