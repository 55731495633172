import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Box, Flex, Text, Image } from "../../../Components/Base"
import { WORK } from "../../../constant"

const HighlightHover = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0px;
  transition: width 0.2s;
  width: 0px;
  height: 100%;
  background: linear-gradient(0deg, #cf0 50%, transparent 0);
  z-index: 0;
`

const SideBarItemContainer = styled(Flex)`
  position: relative;
  width: fit-content;
  color: ${({ theme }) => theme.colors.greyscale.grey3};
  &:hover {
    & > div {
      width: 100%;
    }
  }
`
const SideBarItem = ({ to, indent, title, subtitle, ...restProps }) => {
  return (
    <Link
      to={to}
      style={{ textDecoration: "none", marginBottom: "32px", display: "block" }}
      activeStyle={{
        height: "fit-content",
        width: "fit-content",
        background: "linear-gradient(0deg, #cf0 50%, transparent 0)",
      }}
    >
      <SideBarItemContainer>
        <Box py={2} style={{ zIndex: 1 }}>
          <Text fontSize={4}>{title}</Text>
          <Text fontSize={0} color={"greyscale.grey7"}>
            {subtitle}
          </Text>
        </Box>
        <HighlightHover />
      </SideBarItemContainer>
    </Link>
  )
}

const WorkList = () => {
  const activeWorks = WORK.filter(w => w.active === true)
  const selectedWorks = activeWorks.filter(w => w.isSelectedWork === true)
  const moreWorks = activeWorks.filter(w => !w.isSelectedWork)
  return (
    <Box>
      <Flex alignItems={"center"} mb={5}>
        <Box
          width={"20px"}
          height={"20px"}
          backgroundColor={"primary"}
          mr={3}
        />
        <Text fontSize={4} fontWeight={"light"}>
          Selected Works
        </Text>
      </Flex>
      {selectedWorks.map(work => {
        const { key, name, role, url } = work
        return (
          <SideBarItem key={key} to={url} title={name} subtitle={role}>
            {name}
          </SideBarItem>
        )
      })}
      {/* <Flex alignItems={"center"} my={5}>
        <Box
          width={"20px"}
          height={"20px"}
          backgroundColor={"greyscale.grey5"}
          mr={3}
        />
        <Text fontSize={4} fontWeight={"light"}>
          More Works
        </Text>
      </Flex> */}
      {moreWorks.map(work => {
        const { key, name, role, url } = work
        return (
          <SideBarItem key={key} to={url} title={name} subtitle={role}>
            {name}
          </SideBarItem>
        )
      })}

      <SideBarItem to="/about/">About</SideBarItem>
    </Box>
  )
}

const WorkCard = ({ workId }) => {
  const { role, name, url } = WORK.find(w => w.key === workId)

  const imageUrl = `/assets/work/${workId}/thumbnail.jpg`
  return (
    <Box
      width={[
        "100%",
        "calc((100% - 32px) / 2)",
        "calc((100% - 64px) / 2)",
        "calc((100% - 128px) / 2)",
      ]}
      flex={"0 0 auto"}
    >
      <Link to={url} style={{ display: "block", textDecoration: "none" }}>
        <Image
          src={imageUrl}
          width={"100%"}
          height={["192px", "256px"]}
          style={{ objectFit: "cover" }}
        />

        <Text fontSize={[5, 5, 5, 7]} fontWeight={"semibold"}>
          {name}
        </Text>
        <Text
          fontSize={[5, 5, 5, 7]}
          fontWeight={"semibold"}
          color={"greyscale.grey9"}
        >
          {role}
        </Text>
      </Link>
    </Box>
  )
}

const WorkListContainer = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  /* > div:nth-child(even) {
    margin-top: 128px;
  } */
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    > div:nth-child(even) {
      margin-top: 0;
    }
    > div {
      margin-bottom: 32px;
    }
  }
`

const WorkListV2 = ({ currentWork }) => {
  const activeWorks = WORK.filter(w => w.active === true).filter(
    w => w.key !== currentWork
  )
  const selectedWorks = activeWorks.filter(w => w.isSelectedWork === true)
  const moreWorks = activeWorks.filter(w => !w.isSelectedWork)
  return (
    <Box mt={5}>
      <Flex alignItems={"center"} mb={5}>
        <Box
          width={"20px"}
          height={"20px"}
          backgroundColor={"primary"}
          mr={3}
        />
        <Text fontSize={4} fontWeight={"light"}>
          More Works
        </Text>
      </Flex>

      <WorkListContainer flexDirection={["column", "row"]}>
        {selectedWorks.map(w => {
          return (
            w.active &&
            w.isSelectedWork && <WorkCard key={w.key} workId={w.key} />
          )
        })}
      </WorkListContainer>
    </Box>
  )
}

export default WorkListV2
